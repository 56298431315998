<template>
    
  <!-- Chat -->
  <div class="chat">

    <!-- Chat Content -->
    <div class="chat__content">

      <!-- Empty State -->
      <div v-if="isChatCreated && (!chatItems || chatItems.length === 0)" class="chat__empty">
        <img src="@/assets/chat/ic_empty.svg">
        <div class="chat__empty__label">
          {{ $t('chat.welcome_title') }}
        </div>
        <div class="chat__empty__sublabel">
          {{ $t('chat.welcome_text') }}
        </div>
      </div>
      <div v-else-if="!isChatCreated" class="chat__empty">
        <img src="@/assets/chat/ic_empty.svg">
        <div class="chat__empty__label">
          {{ $t('chat.welcome_title') }}
        </div>
        <div class="chat__empty__sublabel">
          {{ $t('chat.welcome_text') }}
        </div>
      </div>
      
      <!-- Chat Item -->
      <ChatItem v-for="chatItem in chatItems" :reacted="chatItem && chatItem.reactions && chatItem.reactions.length > 0" :key="chatItem.id" @itemClicked="openContextMenu(chatItem)" :chatItem="chatItem" :id="chatItem.id" @answerClicked="answerClicked" @reactionClicked="reactionClicked(chatItem)" v-observe-visibility="animateDiv"></ChatItem>

    </div>

    <!-- Scrollto CTA -->
    <div v-if="this.showScrollToBottom" v-on:click="scrollToBottom()" class="chat__scrollto">
      <img src="@/assets/icons/ic_16_arrow_down.svg">
      {{ $t('chat.newest_message') }}
    </div>

    <!-- Input -->
    <ChatInput v-if="isChatCreated" :answer="isAnswerInput" :answerMessageId="selectedChatItem.messageid" :answerUser="selectedChatItem.username" :answerText="selectedChatItem.text" @textEntered="textEntered" @answerClosed="answerClosed"></ChatInput>

    <!-- Gradient (behind Chat Input) -->
    <div class="chat__gradient"></div>

    <!-- ContextMenu -->
    <ChatContextMenu :show-modal="showModal" :chat-item="selectedChatItem" @emojiClicked="emojiClicked" @replyClicked="replyClicked" @deleteClicked="deleteClicked" @copyClicked="copyClicked" @reportClicked="reportClicked"></ChatContextMenu>

    <!-- Reactions -->
    <ReactionContextMenu :show-modal="showReactionsModal" :chat-item="selectedChatItem" @clickOutside="reactionClickOutside"></ReactionContextMenu>

  </div>

</template>

<script>

import ChatInput from '../components/chat/ChatInput'
import ChatItem from '../components/chat/ChatItem'
import ChatContextMenu from '../components/chat/ChatContextMenu.vue'
import { TOAST_DURATION } from '@/main'
import Vue from 'vue'
import ReactionContextMenu from '@/components/chat/ReactionContextMenu.vue'

export default {
  name: 'Chat',
  components: { ReactionContextMenu, ChatInput, ChatItem, ChatContextMenu },
  data () {
    return {
      showModal: false,
      selectedChatItem: {},
      isAnswerInput: false,
      //interval: null,
      displayDate: '',
      showReactionsModal: false,
      showScrollToBottom: false
    }
  },
  beforeCreate() {
    Vue.$log.debug('Chat beforeCreate')
  },
  created() {
    Vue.$log.debug('Chat created')
    if (this.$route && this.$route.path && this.$route.path !== '/feed/chat') {
      //this.$router.push('/feed/chat')
      this.$router.push({ path: '/feed/chat', replace: true })
    }
    if(this.$store.state.betgame && (!this.$store.state.betgame.chat || this.$store.state.betgame.chat === '')) {
      this.$store.dispatch('fetchBetgame', this.$store.state.betgame.id)
    }
    if(this.$store.state.betgame && (!this.$store.state.betgameUsers || this.$store.state.betgameUsers.length === 0)) {
      this.$store.dispatch('fetchBetgameUsers', this.$store.state.betgame.id)
    }
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    Vue.$log.debug('Chat mounted')
    //if(!this.$store.getters.loggedIn || !this.$store.state.betgame || !this.$store.state.betgame.chat || this.$store.state.betgame.chat === '') {
    //  this.$router.push({ name: 'dashboard' }).catch(err => {})
    //}

    /*
    if(this.$store.state.betgame.chat && this.$store.state.betgame.chat !== '' && this.$store.state.chat_token && this.$store.state.chat_token !== '') {
      if (!this.$store.state.chats_participant.includes(this.$store.state.betgame.chat)) {
        this.$store.dispatch('chatAddParticipant', { user: this.$store.state.user.id, chat: this.$store.state.betgame.chat })
      }
      this.$store.dispatch('setUnreadCount', 0)
      if (this.interval == null) {
        this.interval = setInterval(() => {
          this.$store.dispatch('fetchChatItems', true).then(() => {
            if(this.$store.state.unreadCount > 0) {
              this.$store.dispatch('setUnreadCount', 0)
              setTimeout(() => {
                const e = document.getElementById('1')
                if(e) {
                  e.scrollIntoView()
                }
              }, 500)
            }
          })
        }, 10000)
      }
    }
    */
    
    if(this.$store.state.betgame && this.$store.state.chat_token && this.isAdmin(this.$store.state.betgame.id)) {
      if(!this.$store.state.betgame.chat || this.$store.state.betgame.chat === '') {
        this.$store.dispatch('chatCreateConversation', {
          'title': this.$store.state.betgame.name,
          'participant_ids': ['' + this.$store.state.user.id]
        }).then(response => {
          this.$log.debug('Chat mounted chatCreateConversation response: ' + JSON.stringify(response))
        })
        .catch(function (error) {
          this.$log.debug('Chat mounted chatCreateConversation error: ' + JSON.stringify(error))
        })
      } 
    }

    if(!this.chatItems || this.chatItems.length === 0) {
      this.$store.dispatch('fetchChatItems', true).then(() => {
        setTimeout(() => {
          const e = document.getElementById('1')
          if (e) {
            e.scrollIntoView()
          }
        }, 500)
      })
    }

    setTimeout(() => {
      const e = document.getElementById('1')
      if(e) {
        e.scrollIntoView()
      }
    }, 1200)
  },
  beforeDestroy () {
    //if(this.interval) {
    //  clearInterval(this.interval)
    //}
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    scrollToBottom() {
      const e = document.getElementById('1')
      if (e) {
        e.scrollIntoView()
      }
    },
    reportClicked() {
      this.showModal = false
      this.$router.push({ name: 'reportcreate', params: { item: this.selectedChatItem } }).catch(err => {})
    },
    handleScroll (event) {
      this.showScrollToBottom = (window.scrollY < (document.body.scrollHeight - (2*window.innerHeight)))
      const scrolledTo = window.scrollY + window.innerHeight
      //const isReachBottom = document.body.scrollHeight === Math.round(scrolledTo)
      const isReachBottom = Math.abs(document.body.scrollHeight - Math.round(scrolledTo)) < 10
      if(isReachBottom) {
        this.$log.debug('handleScroll ' + scrolledTo + ' ' + document.body.scrollHeight + ' ' + isReachBottom + ' ' + Math.round(scrolledTo))
        this.$store.dispatch('setUnreadCount', 0)
      }
    },
    animateDiv(isVisible, entry) {
      if(isVisible){
        let domElement = entry.target.id
        const t = this.chatItems.filter(function (item) {
          return item.id === parseInt(domElement)
        })
        if(t.length > 0) {
          this.displayDate = t.sort((a, b) => b.time - a.time)[0].time
        } else {
          this.displayDate = ''
        }
      }
    },
    includesEmoji(list, user, item) {
      var count = 0
      for (let i = 0; i < list.length; i++) {
        if(list[i].text === item && list[i].user_id === user) {
          count++
        }
      }
      return count
    },
    isAdmin(betgame) {
      if(!this.$store.state.betgames_owner) {
        return false
      }
      const t = this.$store.state.betgames_owner.filter(function (item) {
        return item.id === betgame
      });
      return t.length > 0
    },
    updateBetgame (data) {
      this.$store.dispatch('patchBetgame', data)
      .then(() => {
        this.$toast.open({ message: this.$i18n.t('create_chat_success'),
          position: 'top',
          duration: TOAST_DURATION,
          type: 'success'
        })
      })
      .catch(function (error) {
        this.$toast.open({ message: this.$i18n.t('create_chat_error'),
          position: 'top',
          duration: TOAST_DURATION,
          type: 'error'
        })
      })
      .finally(function () {
      })
    },
    openContextMenu(item) {
      this.selectedChatItem = item
      this.showModal = true
    },
    copyClicked(item) {
      this.showModal = false
      if(this.selectedChatItem && this.selectedChatItem.text) {
        navigator.clipboard.writeText(this.selectedChatItem.text)
        .then(() => {
          this.$toast.open({ message: this.$t('feed.message_copied'),
            position: 'top',
            duration: TOAST_DURATION,
            type: 'success'
          })
        })
      }
    },
    deleteClicked() {
      this.showModal = false
      this.isAnswerInput = false
      this.$store.dispatch('chatDeleteMessage', { messageid: this.selectedChatItem.messageid})
      .then(({ data }) => {
        this.selectedChatItem.text = this.$store.state.user.id === this.selectedChatItem.userid ? this.$t('feed.message_deleted_by_you') : this.$t('feed.message_deleted')
        this.selectedChatItem.status = -1
      })
      .catch(function (error) {
      })
    },
    replyClicked() {
      this.showModal = false
      this.isAnswerInput = true
      const scrolledTo = window.scrollY + window.innerHeight
      const CloseToBottom = (document.body.scrollHeight - scrolledTo) < 100
      if(CloseToBottom) {
        setTimeout(function() {
          window.scrollBy({ top: 120, behavior: 'smooth'})
        }, 1)
      }
    },
    emojiClicked(item) {
      this.showModal = false
      if(item && item.selected) {
        if(this.selectedChatItem && this.selectedChatItem.reactions && this.includesEmoji(this.selectedChatItem.reactions, ''+this.$store.state.user.id, item.selected) > 0) {
          const d = {
            'user_id': '' + this.$store.state.user.id,
            'text': item.selected,
            'timestamp': new Date().getTime()
          }
          const data = {
            data: d,
            messageid: this.selectedChatItem.messageid
          }
          this.$store.dispatch('chatReactNessage', data)
          .then(({ data }) => {
            this.$store.dispatch('deleteReaction', {
              messageid: this.selectedChatItem.messageid,
              userid: '' + this.$store.state.user.id,
              reaction: item.selected
            })
          })
          .catch(function (error) {
          })
        } else {
          if(!this.selectedChatItem.reactions) {
            this.selectedChatItem.reactions = []
          }
          const d = {
            'user_id': '' + this.$store.state.user.id,
            'text': item.selected,
            'timestamp': new Date().getTime()
          }
          this.selectedChatItem.reactions.push(d)
          const data = {
            data: d,
            messageid: this.selectedChatItem.messageid
          }
          this.$store.dispatch('chatReactNessage', data)
          .then(({ data }) => {
          })
          .catch(function (error) {
          })
        }
      }
    },
    answerClosed() {
      this.isAnswerInput = false
      this.selectedChatItem = {}
    },
    reactionClicked(item) {
      this.selectedChatItem = item
      this.showReactionsModal = true
    },
    reactionClickOutside() {
      this.showReactionsModal = false
    },
    answerClicked(id) {
      const t = this.chatItems.filter(function (item) {
        return item.messageid === id.id
      })
      if(t.length > 0) {
        document.getElementById(t[0].id).scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
        document.getElementById(t[0].id).classList.add('chat__item--scrolledto')
        setTimeout(function() {
          document.getElementById(t[0].id).classList.remove('chat__item--scrolledto')
        }, 2500)
      }
    },
    textEntered(text) {
      Vue.$log.debug('textEntered ' + JSON.stringify(text))
      this.$store.dispatch('chatAddNessage', {text: text, chat: this.$store.state.betgame.chat})
      .then(({ data }) => {
        Vue.$log.debug('textEntered ok ' + JSON.stringify(data))
        this.$store.dispatch('fetchChatItems', true).then(() => {
          setTimeout(() => {
            const e = document.getElementById('1')
            if(e) {
              e.scrollIntoView()
            }
          }, 500)
        })
      })
      .catch(function (error) {
        Vue.$log.debug('textEntered error ' + JSON.stringify(error))
      })
      this.showModal = false
      this.isAnswerInput = false
      /*
      if(text.in_response_to && text.in_response_to !== '') {
        const t = this.chatItems.filter(function (item) {
          return item.messageid === text.in_response_to
        })
        var in_response_to_message = {}
        if(t.length > 0) {
          in_response_to_message = t[0]
          in_response_to_message._id = t[0].messageid
          in_response_to_message.username = t[0].username
        }
        this.chatItems.push({ id: this.chatItems.length + 1, sender: true, reacted: false, reactions: [], 
          in_response_to: text.in_response_to, in_response_to_message: in_response_to_message, 
          username: this.$store.state.user.nickname, userid: this.$store.state.user.id, time: new Date(), 
          text: text.text })
      } else {
        this.chatItems.push({ id: this.chatItems.length + 1, sender: true, reacted: false, reactions: [],
          username: this.$store.state.user.nickname, userid: this.$store.state.user.id, time: new Date(), text: text.text })
      }
      */
    },
  },
  computed: {
    chatItems() {
      return this.$store.state.chat_items
    },
    isChatCreated() {
      return this.$store.state.betgame && this.$store.state.betgame.chat && this.$store.state.betgame.chat !== ''
    }
  }
}
</script>

<style lang="scss">
.navigation__tabbar {
  position: sticky;
  top: 63px;
  z-index: 500;
  display: flex;
  justify-content: center;
  background: $colorWhite;
  width: 100vw;
  margin: -1px -16px 0;
  box-shadow: inset 0 -1px rgba($colorGreyDark, .24);

  @media screen and (min-width: 768px) {
    margin: -1px calc((100vw - 768px) / -2) 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    width: calc(768px / 2);
    padding: 12px 0;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $colorGreyDark;
    text-decoration: none;

    &-active {
      color: var(--color-primary);
      box-shadow: inset 0 -2px 0 0 var(--color-primary);
    }

    &__label {
      position: relative;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        content: '';
        top: -10px;
        right: -16px;
        min-width: 16px;
        height: 16px;
        padding: 0.5px 0 0 1px;
        border-radius: 8px;
        font-size: 10px;
        font-weight: 500;
        color: $colorWhite;
        background: $colorLive;
        box-sizing: border-box;
      }
    }
  }
}

.chat {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: calc(100% + 32px);
  max-width: 768px;
  margin: 0px -16px;

  @media screen and (min-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }

  &__gradient {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 84px;
    background: linear-gradient(180deg, rgba($colorBackground, 0) 20%, $colorBackground 100%);
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px 12px 0;
    box-sizing: border-box;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
    box-sizing: border-box;
    padding: 0 24px;

    img {
      margin-bottom: 27px;
    }

    &__label {
      font-size: 20px;
      font-weight: 500;
      color: $colorGreyDark;
      margin-bottom: 8px;
    }

    &__sublabel {
      font-size: 14px;
      line-height: 20px;
      color: rgba($colorGreyDark, .64);
    }
  }

  &__divider {

    &--new {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 11px;
      font-weight: 400;
      color: rgba($colorGreyDark, .64);
      white-space: nowrap;
      margin: -2px 0 17px;

      &:before, &:after {
        content: '';
        height: 1px;
        width: calc(100% - 12px);
        background: rgba($colorGreyDark, .12);
      }

      &:before {
        margin-right: 12px;
      }

      &:after {
        margin-left: 12px;
      }
    }

    &--day {
      position: sticky;
      top: 114px;
      display: inline-flex;
      margin: 8px auto 16px;
      background: $colorWhite;
      border-radius: 12px;
      font-size: 11px;
      font-weight: 400;
      color: $colorGreyDark;
      padding: 4px 12px;
      overflow: hidden;

      &--static {
        position: relative;
        top: 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($colorGreyDark, .16);
        border-radius: 12px;
      }
    }
  }

  &__scrollto {
    position: sticky;
    bottom: 80px;
    display: inline-flex;
    margin: 0 auto;
    border-radius: 20px;
    padding: 10px 20px 10px 16px;
    background: $colorWhite;
    box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .16), 0 8px 12px 0 rgba($colorGreyDark, .04);
    font-size: 14px;
    line-height: 20px;
    color: $colorGreyDark;
    cursor: pointer;
    transition: all .2s ease-in-out;

    img {
      margin-right: 4px;
    }

    &:hover {
      box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .24), 0 12px 20px 0 rgba($colorGreyDark, .08);
    }
  }
}
</style>
