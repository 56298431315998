<template>
  <div class="matchdayslider">

    <!-- Headline -->
    <div v-if="this.isBetgame() && !this.isTournament()" class="matchdayslider__headline">
      {{ $t('dashboard.next_matchday') }}
    </div>
    <div v-else class="matchdayslider__headline">
      {{ $t('dashboard.next_matches') }}
    </div>

    <!-- Countdown -->
    <MatchdaySliderCountdown></MatchdaySliderCountdown>

    <!-- Slider -->
    <swiper :options="swiperOption" ref="mySwiper" @slideChange="slideChanged" class="matchdayslider__slider">
      
      <!-- Start Item -->
      <!-- Optional: Nur in "normaler" Tipprunde sichtbar -->
      <swiper-slide v-if="this.isBetgame() && !this.isTournament()" class="matchdayslider__slider__start">
        <div class="matchdayslider__slider__start__badge" v-bind:class="betForAllMatchdayMatches ? 'matchdayslider__slider__start__badge--success' : ''">
          <img src="@/assets/icons/ic_12_check_white.svg">
          <div v-if="!betForAllMatchdayMatches && missingBetsCount > 1">{{ missingBetsCount }} {{ $t('dashboard.x_missing_bets') }}</div>
          <div v-else-if="!betForAllMatchdayMatches && missingBetsCount === 1">{{ $t('dashboard.one_missing_bet') }}</div>
          <div v-else-if="betForAllMatchdayMatches">{{ $t('dashboard.all_mmatches_bet') }}</div>
        </div>
        <div class="matchdayslider__slider__start__matchday">
          {{ this.$store.state.currentMatchdayBet }}.
          <span>{{ $t('matchday_short') }}</span>
        </div>
        <div v-if="!betForAllMatchdayMatches" v-on:click="gotoBet()" class="matchdayslider__slider__start__footer">
          {{ $t('dashboard.bet_now') }}
        </div>
        <div v-else v-on:click="gotoBet()" class="matchdayslider__slider__start__footer">
          {{ $t('dashboard.change_bets') }}
        </div>
      </swiper-slide>

      <!-- Match Item -->
      <swiper-slide v-for="(match, index) in matches" :key="match.id" class="matchdayslider__slider__match" v-bind:class="hasValidBet(match.id) ? '' : 'matchdayslider__slider__match--empty'">

        <!-- Header -->
        <div class="matchdayslider__slider__match__header">
          <div class="matchdayslider__slider__match__header__left">
            {{ getTeamName(match.fk_team_home, match.team_home) }}
          </div>
            {{ getDateFormatted(match.matchdate) }}
          <div class="matchdayslider__slider__match__header__right">
            {{ getTeamName(match.fk_team_guest, match.team_guest) }}
          </div>
        </div>

        <!-- Teams & Result -->
        <div class="matchdayslider__slider__match__teams">

          <!-- Home Team -->
          <div class="matchdayslider__slider__match__teams__left">
            <!-- <img src="@/assets/jerseys/bundesliga1/72/jersey_wolfsburg.png"> -->
            <img v-if="teamCode(match.fk_team_home) && teamCode(match.fk_team_home).length > 6" :src="teamLogo(match.fk_team_home)">
            <flag v-else-if="teamCode(match.fk_team_home) && teamCode(match.fk_team_home).length <= 6" :iso="teamCode(match.fk_team_home)" />
            <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getColor(match.fk_team_home)}"></path>
            </svg>
          </div>

          <!-- Tipp -->
          <div class="matchdayslider__slider__match__teams__center">

            <!-- Result -->
            <div v-if="$store.state.betgame.bet_mode === 1" class="matchdayslider__slider__match__teams__bet">
              <div class="matchdayslider__slider__match__teams__bet__left">
                {{ getBet(match.id).goalshome }}
              </div>
              <div class="matchdayslider__slider__match__teams__bet__center">
                :
              </div>
              <div class="matchdayslider__slider__match__teams__bet__right">
                {{ getBet(match.id).goalsguest }}
              </div>
            </div>
            <div v-else-if="$store.state.betgame.bet_mode === 2" class="matchdayslider__slider__match__teams__bet">
              <div class="matchdayslider__slider__match__teams__bet__toto" v-bind:class="getClass(match, $store.state.user.id, 1)">
                1
              </div>
              <div class="matchdayslider__slider__match__teams__bet__toto" v-bind:class="getClass(match, $store.state.user.id, 0)">
                0
              </div>
              <div class="matchdayslider__slider__match__teams__bet__toto" v-bind:class="getClass(match, $store.state.user.id, 2)">
                2
              </div>
            </div>
            
            <div v-if="getBet(match.id).goalshome !== '-'" class="matchdayslider__slider__match__teams__bet__label">
              {{ $t('dashboard.your_bet') }}
            </div>

          </div>

          <!-- Away Team -->
          <div class="matchdayslider__slider__match__teams__right">
            <!-- <img src="@/assets/jerseys/bundesliga1/72/jersey_dortmund.png"> -->
            <img v-if="teamCode(match.fk_team_guest) && teamCode(match.fk_team_guest).length > 6" :src="teamLogo(match.fk_team_guest)">
            <flag v-else-if="teamCode(match.fk_team_guest) && teamCode(match.fk_team_guest).length <= 6" :iso="teamCode(match.fk_team_guest)" />
            <svg v-else width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path d="M16.6788228,14.4666528 C16.6784319,14.5535821 16.6782365,14.6380312 16.6782365,14.72 C16.6782365,17.92 17.0754204,17.92 17.0754204,22 L6.90751142,22 C6.90751142,18 7.30469536,18 7.30469536,14.72 C7.30469536,14.5820958 7.30416879,14.4377219 7.30311564,14.2868783 C7.16189664,12.9183228 6.95059177,11.7026967 6.66920105,10.64 L6.38887711,11.2610852 C6.32215943,11.4089048 6.15565267,11.4837271 6.0008221,11.4354635 L3.22551831,10.5703504 C3.06290094,10.5196596 2.96820511,10.350765 3.00978727,10.1855836 L4.33308578,4.92889834 C4.35386243,4.84636495 4.40656906,4.77546278 4.47961453,4.73178454 L6.72830973,3.38715539 C6.78448244,3.35361757 6.84801543,3.33879392 6.91053769,3.34222149 C6.90953086,3.3215047 6.9085221,3.3007642 6.90751142,3.28 L9.40273013,2 C10.2740705,2.5 11.1398271,2.75 12,2.75 C12.8601729,2.75 13.7202401,2.5 14.5802017,2 L16.8912572,3.18552777 C16.96049,3.22094519 17.0129393,3.27936302 17.0415261,3.3484881 C17.11879,3.33240258 17.2010587,3.34498492 17.2716903,3.38715539 L19.5203855,4.73178454 C19.5934309,4.77546278 19.6461376,4.84636495 19.6669142,4.92889834 L20.9902127,10.1855836 C21.0317949,10.350765 20.9370991,10.5196596 20.7744817,10.5703504 L17.9991779,11.4354635 C17.8443473,11.4837271 17.6778406,11.4089048 17.6111229,11.2610852 L17.330799,10.64 C17.0371467,11.7490035 16.8198213,13.0245545 16.6788228,14.4666528 Z" id="basic-jersey" :style="{'fill': getColor(match.fk_team_guest)}"></path>
            </svg>
          </div>

        </div>

        <!-- Footer -->
        <div v-if="isNumeric(match.goalshome) || isNumeric(match.livegoalshome)" v-on:click="gotoBet()" class="matchdayslider__slider__match__footer">
          {{ $t('dashboard.view_bet') }}
        </div>
        <div v-else-if="getBet(match.id).goalshome === '-'" v-on:click="gotoBet()" class="matchdayslider__slider__match__footer">
          {{ $t('dashboard.add_bet') }}
        </div>
        <div v-else v-on:click="gotoBet()" class="matchdayslider__slider__match__footer">
          {{ $t('dashboard.change_bet') }}
        </div>

      </swiper-slide>

    </swiper>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import MatchdaySliderCountdown from './../components/MatchdaySliderCountdown'
import { isNumeric } from '@/vuex/helpers'

export default {
  name: 'MatchdaySlider',
  components: { swiper, swiperSlide, MatchdaySliderCountdown },
  data () {
    return {
      swiperOption: {
        grabCursor: true,
        autoHeight: false,
        slidesPerView: 'auto',
        spaceBetween: 8,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      }
    }
  },
  beforeCreate () {
  },
  created () {
  },
  mounted () {

  },
  computed: {
    matches() {
      if(this.isBattle()) {
        const m = this.$store.state.matches.filter(match => (!isNumeric(match.goalshome) && !isNumeric(match.goalsguest))).sort((a, b) =>
            a.matchdate === b.matchdate ? a.id - b.id : this.parseMatchdate(a.matchdate) - this.parseMatchdate(b.matchdate))
        if (m.length > 5) {
          return m.slice(0, 5)
        }
        return m
      } else if(this.isTeamsBetgame()) {
        const m = this.$store.state.matches.filter(match => (!isNumeric(match.goalshome) && !isNumeric(match.goalsguest))).sort((a, b) =>
            a.matchdate === b.matchdate ? a.id - b.id : this.parseMatchdate(a.matchdate) - this.parseMatchdate(b.matchdate))
        if (m.length > 5) {
          return m.slice(0, 5)
        }
        return m
      } else {
        const temp = this.$store.getters.getMatchesByMatchday(this.$store.state.currentMatchdayBet).sort((a, b) =>
            a.matchdate === b.matchdate ? a.id - b.id : this.parseMatchdate(a.matchdate) - this.parseMatchdate(b.matchdate))
        if (!this.isBetgame() && temp.length > 5) {
          return temp.slice(0, 5)
        } else if (this.isBetgame() && this.$store.state.betgame.competition.id === 19 && this.$store.state.betgame.season.id > 14) {
          return temp.slice(0, 18)
        } else if (this.isBetgame() && this.$store.state.betgame.competition.id === 132 && this.$store.state.betgame.season.id > 14) {
          return temp.slice(0, 18)
        } else if (this.isBetgame() && this.$store.state.betgame.competition.id === 6395 && this.$store.state.betgame.season.id > 14) {
          return temp.slice(0, 18)
        }
        return temp
      }
    },
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    nextMatch() {
      if(this.$store.state.matches.length > 0) {
        return this.$store.getters.getClosestFutureMatch
      } else {
        if(this.$store.state.leagueId) {
          this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
        } else {
          if(this.getBGValue('spmbgid') && parseInt(this.getBGValue('spmbgid')) > 0) {
            this.$store.dispatch('fetchBetgameLeague', this.getBGValue('spmbgid'))
          }
        }
      }
    },
    betForAllMatchdayMatches () {
      const bets = this.$store.state.bets
      var result = false
      if (bets.length > 0 && this.nextMatch) {
        const matchdayMatches = this.$store.getters.getMatchesByMatchday(this.nextMatch.matchday)
        if (matchdayMatches.length > 0) {
          for (var i = 0; i < matchdayMatches.length; i++) {
            const match = matchdayMatches[i]
            if(this.isPast(match.matchdate)) {
              continue
            }
            if(match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt'
                || match.status.trim() === 'verlegt' || match.status.trim() === 'Wertung')) {
              continue
            }
            const betMatches = bets.filter(betMatch => betMatch.fk_match === match.id)
            if (betMatches.length === 0) {
              return false
            } else {
              result = true
            }
          }
        }
      }
      return result
    },
    missingBetsCount () {
      const bets = this.$store.state.bets
      var result = 0
      if (bets.length > 0 && this.nextMatch) {
        const matchdayMatches = this.$store.getters.getMatchesByMatchday(this.nextMatch.matchday)
        if (matchdayMatches.length > 0) {
          for (var i = 0; i < matchdayMatches.length; i++) {
            const match = matchdayMatches[i]
            if(this.isPast(match.matchdate)) {
              continue
            }
            if(match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt'
                || match.status.trim() === 'verlegt' || match.status.trim() === 'Wertung')) {
              continue
            }
            const betMatches = bets.filter(betMatch => betMatch.fk_match === match.id)
            if (betMatches.length === 0) {
              result++
            } 
          }
        }
      } else if(bets.length === 0 && this.nextMatch) {
        const matchdayMatches = this.$store.getters.getMatchesByMatchday(this.nextMatch.matchday)
        return matchdayMatches.length
      }
      return result
    }
  },
  methods: {
    getClass(match, user, bettype ) {
      const bet = this.$store.getters.getAllBetByMatchUser(this.$store.state.betgameId, match.id, user)
      if(bet !== undefined && bet !== null) {
        return { 'matchdayslider__slider__match__teams__bet__toto--selected': bet.goalshome === bettype }
      }
      return { 'matchdayslider__slider__match__teams__bet__toto--selected': false }
    },
    isNumeric(n) {
      if(n === null || n === undefined) {
        return false
      }
      return !isNaN(parseFloat(n)) && isFinite(n)
    },
    gotoBet() {
      this.$router.push({ name: 'bet' }).catch(err => {})
    },
    hasValidBet(match) {
      const bets = this.$store.state.bets.filter(bet => bet.fk_match === match && bet.fk_user === this.$store.state.user.id)
      if(bets.length === 1) {
        const b = bets[0]
        const gh = parseInt(b.goalshome)
        const gg = parseInt(b.goalsguest)
        if (gh >= 0 && gg >= 0) {
          return true
        }
      }
      return false
    },
    getBet(match) {
      const bets = this.$store.state.bets.filter(bet => bet.fk_match === match && bet.fk_user === this.$store.state.user.id)
      if(bets.length === 1) {
        const b = bets[0]
        const gh = parseInt(b.goalshome)
        const gg = parseInt(b.goalsguest)
        if(gh >= 0 && gg >= 0) {
          return { goalshome: b.goalshome, goalsguest: b.goalsguest }
        } else {
          return { goalshome: '-', goalsguest: '-' }
        }
        return { goalshome: gh, goalsguest: gg }
      } else {
        return { goalshome: '-', goalsguest: '-' }
      }
    },
    getColor(teamId)  {
      const team = this.$store.getters.getTeamById(teamId)
      if(team != null && team.color !== null && team.color.length > 0) {
        return team.color
      }
      return '#778833'
    },
    slideChanged() {
      
    },
    teamCode(team) {
      const l = this.getTeamLogo(team)
      if(l) {
        return l
      }
      return null
    },
    teamLogo(team) {
      const l = this.getTeamLogo(team)
      if(l) {
        return this.getBGValue('spmau') + '/images/jerseys/72/' + l
      }
      return null
    }
  }
}
</script>

<style lang="scss">
  .matchdayslider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba($colorGreyDark, .08);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    box-sizing: border-box;
    width: 100%;
    
    &__headline {
      font-size: 17px;
      font-weight: 500;
      color: $colorGreyDark;
      text-align: center;
      margin: 4px 0 12px;
    }

    &__slider {
      width: calc(100% + 64px);
      margin: 0 -32px;
      padding: 16px 32px;
      box-sizing: border-box;

      @media screen and (min-width: 768px) {
        width: calc(100% + 32px);
        margin: 0 -16px;
      }

      &__start {
        position: relative;
        width: 140px;
        background: var(--color-primary);
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0 4px 16px 0 rgba($colorGreyDark, .08);
        padding: 0 !important;
        transition: all .2s ease-in-out;
        
        @media screen and (min-width: 768px) {
          &:hover {
            box-shadow: 0 8px 20px 0 rgba($colorGreyDark, .2);
            transform: translateY(-4px);
          }
        }

        &:before {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          bottom: 40px;
          width: 100%;
          height: calc(100% - 40px);
          background: rgba($colorWhite, .06);
          -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%);
          clip-path: polygon(100% 0, 100% 100%, 0 100%);
        }

        &__badge {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
          font-size: 11px;
          font-weight: 500;
          color: $colorWhite;
          text-align: center;
          margin: 8px;
          border-radius: 4px;
          padding: 6px 0 5px;
          background: $colorNegative;

          img {
            display: none;
            width: 10px;
            height: 10px;
            margin: 0 4px 0 0;
            transform: rotate(5deg) translateY(-1px);
          }

          &--success {
            background: $colorPositive;

            img {
              display: flex;
            }
          }
        }

        &__matchday {
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: center;
          justify-content: center;
          font-size: 48px;
          font-weight: 500;
          color: $colorWhite;
          margin: 16px 0 32px;

          span {
            font-size: 17px;
            font-weight: 400;
            margin-top: -6px;
          }
        }

        &__footer {
          position: relative;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
          font-size: 14px;
          font-weight: 500;
          color: $colorWhite;
          background: var(--color-primary);
          box-shadow: 0 -1px 0 0 rgba($colorWhite, .16);
        }
      }

      &__match {
        background: $colorWhite;
        border-radius: 8px;
        box-shadow: 0 0 1px 0 rgba($colorGreyDark, .12), 0 4px 16px 0 rgba($colorGreyDark, .08);
        width: 100%;
        overflow: hidden;
        transition: all .2s ease-in-out;

        @media screen and (min-width: 768px) {
          max-width: 350px;
        }

        @media screen and (min-width: 768px) {
          &:hover {
            box-shadow: 0 0 1px 0 rgba($colorGreyDark, .12), 0 8px 20px 0 rgba($colorGreyDark, .2);
            transform: translateY(-4px);
          }
        }

        &__header {
          display: flex;
          padding: 16px 0 0;
          box-sizing: border-box;
          font-size: 14px;
          color: $colorGreyDark;

          &__left, &__right {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &__left {
            text-align: left;
            margin-right: 2px;
          }
          &__right {
            text-align: right;
            margin-left: 2px;
          }
        }

        &__teams {
          display: flex;
          justify-content: center;
          height: 127px;

          &__left {
            display: flex;
            width: 80px;
            height: 100%;
            align-items: center;

            img, svg {
              margin-left: -72px;
              margin-top: 48px;
              width: 172px !important;
              height: 172px;
              margin-bottom: 0;
              flex-shrink: 0;
            }

            .flag-icon {
              width: 64px !important;
              height: 64px !important;
              border-radius: 50%;
              margin-left: 8px;
              box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .16);
            }
          }

          &__center {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin: 0 auto;
          }

          &__right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 80px;
            height: 100%;

            img, svg {
              display: flex;
              align-self: flex-start;
              margin-right: -72px;
              width: 172px !important;
              height: 172px;
              margin-bottom: 0;
              flex-shrink: 0;
            }

            .flag-icon {
              width: 64px !important;
              height: 64px !important;
              border-radius: 50%;
              margin-right: 8px;
              box-shadow: inset 0 0 1px 0 rgba($colorGreyDark, .16);
            }
          }

          &__bet {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 24px;

            .matchdayslider__slider__match--empty & {
              padding-top: 30px;
            }

            &__left, &__center, &__right {
              font-size: 48px;
              font-weight: 500;
              color: $colorGreyDark;

              .matchdayslider__slider__match--empty & {
                color: rgba($colorGreyDark, .12);
              }
            }

            &__left {
              flex: 1;
              text-align: right;
            }

            &__center {
              margin: 0 4px;
            }

            &__right {
              flex: 1;
              text-align: left;
            }

            &__toto {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 32px;
              height: 32px;
              font-size: 20px;
              font-weight: 500;
              color: rgba($colorGreyDark, .24);
              background: #F1F1F5;
              border-radius: 4px;
              margin: 12px 0;

              &:nth-child(2) {
                margin: 12px 4px;
              }

              &--selected {
                color: $colorWhite;
                background: var(--color-primary);
              }
            }

            &__label {
              font-size: 14px;
              color: rgba($colorGreyDark, .48);
              margin-top: -3px;
            }
          }
        }

        &__footer {
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: var(--color-primary);
          background: $colorWhite;
          box-shadow: 0 -1px 0 0 rgba($colorGreyDark, .08);
          width: calc(100% + 32px);
          margin: 0 -32px;
          padding: 10px 16px;

          .matchdayslider__slider__match--empty & {
            color: $colorWhite;
            background: var(--color-primary);
            box-shadow: none;
          }
        }
      }
    }
  }
</style>
